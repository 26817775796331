import React from "react"
import Container from "components/utils/container"
import ctl from "@netlify/classnames-template-literals"
import { StaticImage } from "gatsby-plugin-image"
import ButtonGroup from "../../utils/button/group"
import "./intro.css"
import AboutUsAnimation from "../../animations/about-us-animation"

const AboutUsIntro = ({ heading, description, image }) => {
  return (
    <div className="relative about-intro">
      <Container size='large' className={containerStyle}>
        <header className={headerStyle}>
          <p className={tagStyle}>About us</p>
          <h1 className={headingStyle}>About Egmarkets?</h1>
          <p className={descriptionStyle}>
            The average Nigerian has no knowledge <br className='mobile-breakpoint'/>
            of Financial markets and how to take <br className='mobile-breakpoint'/>
            advantage of It. That’s where we come in.
          </p>

          <ButtonGroup className='md-max:gap-y-[20px]' fullWidthOnMobile buttons={buttons} />

          <a href='https://financialcommission.org/eagle-global-markets/' target='_blank' className={financialCommissionStyle}>
            <StaticImage
              src="../../../assets/images/pages/home/financial-commission.webp"
              className="w-full"
            />
          </a>
        </header>

        <div className={imageWrapperStyle}>
          <AboutUsAnimation/>
        </div>
      </Container>
    </div>
  )
}

export default AboutUsIntro

const buttons = [
  {
    label: "Open An Account",
    url: "https://clients.eagleglobalmarkets.com/#/step-one",
    isExternal: true,
    className: 'md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px] md-max:text-sm'
  },
  {
    label: "Try Demo Trading",
    url: "https://mt4.egmarkets.trade/",
    isExternal: true,
    className: 'md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px] md-max:text-sm'
  },
]
const containerStyle = ctl(
  ` flex lg:flex-row flex-col lg:pt-[40px] justify-between items-center`
)

const headerStyle = ctl(
  `lg:text-left text-center max-w-[400px] md:mx-0 mx-auto`
)
const tagStyle = ctl(`text-[11px] md-max:mt-10 mb-0 font-bold uppercase`)

const headingStyle = ctl(
  `md:text-[40px] md:mt-[5px] pt-0 text-[32px]`
)
const descriptionStyle = ctl(`md:text-xl font-normal text-base lg:mt-[30px]`)

const imageWrapperStyle = ctl(
  `
      lg:mt-0
      mt-12 md:max-w-[500px] 
      md:mx-0 mx-auto 
      md:w-10/12 md:w-auto 
      md-max:w-full md-max:max-w-[336px]
      `
)

const financialCommissionStyle = ctl(
  `md-max:hidden md:max-w-[343px] w-[343px] lg:mx-0 mx-auto md:mt-[50px] mt-[30px] hidden`
)
