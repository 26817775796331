import React from "react"
import Container from "../../utils/container"

import ctl from "@netlify/classnames-template-literals"
import rightBarrelShape from "icons/opportunities/shape-right.svg"
import leftBarrelShape from "icons/opportunities/shape-left.svg"
import rightBarrelShapeMobile from "icons/opportunities/shape-right-light-m.svg"
import leftBarrelShapeMobile from "icons/opportunities/shape-left-m.svg"

const WhyWeAreHere = () => {
  return (
    <div className="relative">
      <Container size="small" className={containerStyle}>
        <img src={leftBarrelShape} alt="" className={`${barrelLeftStyle} md-max:hidden`} />
        <img src={rightBarrelShape} alt="" className={`${barrelRightStyle} md-max:hidden`} />
        <img src={leftBarrelShapeMobile} alt="" className={`${barrelLeftStyle} md:hidden`} />
        <img src={rightBarrelShapeMobile} alt="" className={`${barrelRightStyle} md:hidden`} />
        <h2>Why We Are Here</h2>
        <p>
          Conceived in a kitchen in the United Kingdom in 2014, established in the
          business nucleus of the black continent in 2016, our mission here at
          Eagle Global Markets has always been unitary: to connect Africans to
          opportunities in the global financial markets
        </p>

        <p>
          As the only Africans on the continent to own a brokerage firm, our
          concerns are firmly localized and rooted in disruption: we aim to
          provide African investors, regardless of social status, age or exposure,
          with an intuitive and cost-effective platform where they can trade a
          plethora of options either in foreign or their local currency.
        </p>

        <p className="italic">
          We aim to democratise financial freedom for all while creating the best
          trading <br/> experience on the African continent through innovation and
          technology
        </p>
      </Container>
    </div>
  )
}

const containerStyle = ctl(`
text-center 
md:text-lg
md:pt-[82px]
md:pb-[100px]
pt-[40px]
pb-[60px]
md-max:px-[50px]
`)

const barrelRightStyle = ctl(`shape block md-max:w-[294px] right-[-270px] md:right-[-300px] top-[0px] md:top-[-90px]`)

const barrelLeftStyle = ctl(`
  shape block left-[-276px] md-max:w-[294px] md-max:bottom-[90px] md:left-[-330px] md:top-[180px]`)

export default WhyWeAreHere
