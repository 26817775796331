import * as React from "react"
import Layout from "../components/layout"
import { WhyWeAreHere } from "../components/pages/about-us"
import AboutUsIntro from "../components/pages/about-us/intro"
import MediaMentions from "../components/utils/media"
import TradingSteps from "../components/utils/trading/steps"
import Testimonials from "../components/pages/about-us/testimonials"

const AboutUsPage = () => (
  <Layout
    description='Egmarkets offers various financial instruments, trading software, and tools to ensure you trade the financial market with ease.'
    title="About Egmarkets"
    ignoreSiteName
  >
    <AboutUsIntro />
    <WhyWeAreHere />
    <Testimonials/>
    <MediaMentions className='md-max:mb-[100px]'/>
    <TradingSteps long/>
  </Layout>
)

export default AboutUsPage
